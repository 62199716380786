import React, { useState, useEffect } from 'react';
import ReactGA from "react-ga4";

import './styles/App.css'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import Footer from './components/Footer';

/*
const dsp_links = [	
	{
		title: 'Pandora',
		link: "https://www.pandora.com/artist/alice-cooper/AR3V6m4Pmrxmg9q",
		icon: faMusic,
		from: 'fontawesome'
	},
	{
		title: 'Amazon Music',
		link: "https://www.amazon.com/Alice-Cooper/e/B000AP9PB0",
		icon: faAmazon,
		from: 'fontawesome'
	},
	{
		link: "https://www.tiktok.com/@alicecooperofficial",
		icon: faTiktok,
		from: 'fontawesome'
	}
]
*/

import CoheedName from './assets/images/CoheedName.png'
// import Symbol from './assets/SYMBOL.svg'
import Symbol from './assets/images/CoheedAndCambria-Logo.png'

import TFOMBCover from './assets/images/COCA_TFOMB_Cover.webp';
// import HelmetMov from './assets/images/Coheed_Helmet_Animation.mov'

import BoxSet from './assets/images/Coheed_Vaxis2_Deluxe_Boxset.png';

export default function App() {

	const [ show, setShow ] = useState(true);

	useEffect(() => {
		ReactGA.initialize("G-8N5CEEJ47F");
		ReactGA.send({ hitType: "page_view", page: "/", title: "Coheed And Cambria" });
	}, [])

	const openOrderLink = () => {
		window.open('http://store.coheedandcambria.com', '_blank', 'noopener,noreferrer');
	}

	// TODO: work on this... 
	/*
	const openLink = (url, link_title) => {
		// ReactGA.send({ hitType: "click_link", page: "/", title: link_title }); // TODO: confirm parameters that you can send for events...
		window.open(url, '_blank', 'noopener,noreferrer');
	}
	*/

	function handleClose() {
		setShow(false);
	}
	
	return (
		<div className="App text-center">

			<Modal show={show} onHide={handleClose} size="lg">
				<Modal.Body className="d-flex flex-column flex-md-row">
					
					<a href="https://bnds.us/im51l0" target="_blank" rel="noopener noreferrer">
						<Image src={`images/TakingBackSunday_resized.png`} alt="Taking Back Sunday" fluid />
					</a>
					<a href="https://bnds.us/im51l0" target="_blank" rel="noopener noreferrer">
						<Image src={`images/Infinite_Arc_resized.png`} alt="Infinite Arc Tour" fluid />
					</a>
					
				</Modal.Body>
				<Modal.Footer style={{ textAlign: 'center' }}>
					<a href="https://bnds.us/im51l0" target="_blank" rel="noopener noreferrer" className="btn btn-secondary" style={{ fontSize: '1.25rem', backgroundColor: '#000000', fontWeight: 'bold', margin: '0 auto', color: '#ffffff', border: 'solid 1px #000000' }}>
						Tickets
					</a>
					{/* <button type="button" className="btn" style={{ backgroundColor: '#000000', fontWeight: 'bold', margin: '0 auto', color: '#ffffff', border: 'solid 1px #000000' }} onClick={handleClose}>
						Close Window
					</button> */}
				</Modal.Footer>
			</Modal>

			<div style={{ backgroundColor: '#c13e46' }}>
				<Image src={CoheedName} alt="Coheed and Cambria" className="mx-auto" fluid style={{ margin: '30px 0', padding: '0 30px' }} />
			</div>

			<div className="bg-black d-flex flex-column justify-content-center px-3" style={{ fontSize: '2rem', backgroundImage: `url(${Symbol})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', minHeight: '440px' }}>
				<h2 className="title">VAXIS - ACT III:</h2>
				<h1 className="title">THE FATHER OF MAKE BELIEVE</h1>
				<p className="sub2">Limited Edition Deluxe Box Set <a href="https://store.coheedandcambria.com" target="_blank" rel="noreferrer" className="fw-bolder text-decoration-underline">Pre-Order</a>
				</p>
			</div>

			<div className="rowWrapper">
					
				<Container>
					
					<Row className="flex-row-reverse flex-lg-row">
						<Col lg={6} className="pt-3">
							<a href="https://store.coheedandcambria.com" target="_blank" rel="noreferrer"><Image src={BoxSet} alt="BoxSet" fluid /></a>
						</Col>
						<Col lg={6} className="pt-3 d-flex flex-column justify-content-center">
							<video autoPlay loop muted>
								<source src={'images/Coheed_Helmet_Animation.mov'} type="video/mp4"></source>
							</video>
						</Col>
					</Row>

					<Row className="justify-content-center">
						<Col lg={8}>
							<div className="mx-auto my-4">
								<h4 className="py-3 sectionHeading mt-1">Box Set includes:</h4>
								<div className="py-3">
									<ul className="item-list">
										<li>Vaxis - Act III: The Father of Make Believe Hardcover Illustrated 9"x12" Novella Coffee Table Book (Exclusive) (96pp)</li>
										<li>Blind Side Sonny's Fully Wearable and Functional Helmet and Neuro-Dynamic Display Goggles (Exclusive)</li>
										<li>Neuro-Dynamic Display "Bloggles" App for Display Customization (Exclusive)</li>
										<li>11.5”x35” Vaxis III: The Father of Make Believe Bedroom Poster (Exclusive)</li>
										<li>The Death Certificate of Siv Trafinder (Signed)  (Exclusive)</li>
										<li>Collected in a 12”x16” Limited Edition Custom Foil Stamped Box (Exclusive)</li>
										<li>Coheed and Cambria “The Father of Make Believe” CD and Digital Download</li>
									</ul>
									<Button className="btn-lg actionBtn my-3 shadow-lg" onClick={openOrderLink}>PRE-ORDER NOW</Button>
									<p className="date">AVAILABLE MARCH 14, 2025</p>
								</div>
							</div>
						</Col>
					</Row>

					<Row className="justify-content-center" style={{ margin: '25px 0 0 0' }}>
						<Col lg={8}>
							<h2 className="sectionHeading mt-3">
								New Single<br />“Someone Who Can”
							</h2>
							<div className="video-container">
								<iframe width="100%" height="auto" 
									// width="560" height="315" 
									src="https://www.youtube.com/embed/mld4KuTNg0M?si=nqZgz0SEggJyIDV3" 
									title="YouTube video player" frameBorder="0" 
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
									referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
								</iframe>
							</div>
						</Col>
					</Row>
					<Row className="justify-content-center" style={{ margin: '0 0 100px 0'}}>
						<Col>
							<Button className="btn-lg actionBtn shadow-lg" onClick={() => window.open('https://coheedandcambria.lnk.to/someonewhocan', '_blank', 'noopener,noreferrer')}>LISTEN NOW</Button>
						</Col>
					</Row>

					<Row>
						<Col lg={6} className="pt-3">
							<Image src={TFOMBCover} alt="BoxSet" fluid />
						</Col>
						<Col lg={6} className="pt-3 text-start">
							<h4 className="sectionHeading" style={{ fontSize: '1.2rem' }}>The Father of Make Believe</h4>
							<div className="py-3">
								<ol className="item-list ps-0 text-start">
									<li>Yesterday's Lost</li>
									<li>Goodbye, Sunshine</li>
									<li>Searching For Tomorrow</li>
									<li>The Father of Make Believe</li>
									<li>Meri of Mercy</li>
									<li>Blind Side Sonny</li>
									<li>Play The Poet</li>
									<li>One Last Miracle</li>
									<li>Corner My Confidence </li>
									<li>Someone Who Can</li>
									<li>The Continuum I: Welcome to Forever, Mr. Nobody</li>
									<li>The Continuum II: The Flood</li>
									<li>The Continuum III: Tethered Together</li>
									<li>The Continuum IV: So It Goes</li>
								</ol>
								<div style={{ width: 'fit-content' }} className='text-center'>
									<Button className="btn-lg actionBtn my-3 shadow-lg d-block mx-auto" onClick={() => window.open('https://coheedandcambria.lnk.to/tfomb', '_blank', 'noopener,noreferrer')}>PRE-SAVE</Button>
									<p className="text-start">
										AVAILABLE MARCH 14, 2025
									</p>
								</div>
								
							</div>
						</Col>
					</Row>
				</Container>
				<Footer />
			</div>
			{/*
  			<a class="bit-widget-initializer"
				data-artist-name="id_129"
				data-events-to-display=""
				data-background-color="#ffffff"
				data-separator-color="#DDDDDD"
				data-text-color="#424242"
				data-font="Helvetica"
				data-auto-style="true"
				data-button-label-capitalization="uppercase"
				data-header-capitalization="uppercase"
				data-location-capitalization="uppercase"
				data-venue-capitalization="capitalize"
				data-display-local-dates="true"
				data-local-dates-position="tab"
				data-display-past-dates="true"
				data-display-details="false"
				data-display-lineup="false"
				data-display-start-time="false"
				data-social-share-icon="false"
				data-display-limit="5"
				data-date-format="MMM. D, YYYY"
				data-date-orientation="horizontal"
				data-date-border-color="#08C3BA"
				data-date-border-width="1px"
				data-date-capitalization="capitalize"
				data-date-border-radius="10px"
				data-event-ticket-cta-size="medium"
				data-event-custom-ticket-text=""
				data-event-ticket-text="TICKETS"
				data-event-ticket-icon="false"
				data-event-ticket-cta-text-color="#FFFFFF"
				data-event-ticket-cta-bg-color="rgba(74,74,74,1)"
				data-event-ticket-cta-border-color="rgba(74,74,74,1)"
				data-event-ticket-cta-border-width="1px"
				data-event-ticket-cta-border-radius="200px"
				data-sold-out-button-text-color="#FFFFFF"
				data-sold-out-button-background-color="#9B9B9B"
				data-sold-out-button-border-color="rgba(155,155,155,1)"
				data-sold-out-button-clickable="true"
				data-event-rsvp-position="left"
				data-event-rsvp-cta-size="medium"
				data-event-rsvp-only-show-icon="false"
				data-event-rsvp-text="REMIND ME"
				data-event-rsvp-icon="true"
				data-event-rsvp-cta-text-color="rgba(74,74,74,1)"
				data-event-rsvp-cta-bg-color="#FFFFFF"
				data-event-rsvp-cta-border-color="rgba(74,74,74,1)"
				data-event-rsvp-cta-border-width="0px"
				data-event-rsvp-cta-border-radius="200px"
				data-follow-section-position="top"
				data-follow-section-alignment="left"
				data-follow-section-header-text="Get updates on new shows, new music, and more"
				data-follow-section-cta-size="medium"
				data-follow-section-cta-text="FOLLOW"
				data-follow-section-cta-icon="false"
				data-follow-section-cta-text-color="rgba(74,74,74,1)"
				data-follow-section-cta-bg-color="rgba(255,255,255,1)"
				data-follow-section-cta-border-color="rgba(74,74,74,1)"
				data-follow-section-cta-border-width="1px"
				data-follow-section-cta-border-radius="200px"
				data-play-my-city-position="bottom"
				data-play-my-city-alignment="left"
				data-play-my-city-header-text="Don’t see a show near you?"
				data-play-my-city-cta-size="medium"
				data-play-my-city-cta-text="PLAY MY CITY"
				data-play-my-city-cta-icon="false"
				data-play-my-city-cta-text-color="rgba(74,74,74,1)"
				data-play-my-city-cta-bg-color="rgba(255,255,255,1)"
				data-play-my-city-cta-border-color="rgba(74,74,74,1)"
				data-play-my-city-cta-border-width="1px"
				data-play-my-city-cta-border-radius="200px"
				data-optin-font=""
				data-optin-text-color=""
				data-optin-bg-color=""
				data-optin-cta-text-color=""
				data-optin-cta-bg-color=""
				data-optin-cta-border-width=""
				data-optin-cta-border-radius=""
				data-optin-cta-border-color=""
				data-language="en"
				data-layout-breakpoint="900"
				data-app-id=""
				data-affil-code=""
				data-bit-logo-position="bottomRight"
				data-bit-logo-color="#424242"></a>
			*/}
		</div>
	);
};